import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/storefront/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/app/(main)/skeleton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/storefront/src/lib/providers/antd-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/vercel/path0/storefront/src/lib/providers/cart/cart-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MedusaProvider"] */ "/vercel/path0/storefront/src/lib/providers/medusa-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RegionProvider"] */ "/vercel/path0/storefront/src/lib/providers/region-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomerProvider"] */ "/vercel/path0/storefront/src/lib/providers/user/user-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/src/modules/common/components/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/storefront/src/modules/common/components/menu/index.tsx");
